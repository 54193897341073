import { merge, Observable } from 'rxjs';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';

import { inject, Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MatLegacyDialogState as MatDialogState } from '@angular/material/legacy-dialog';

@Injectable({
	providedIn: 'root',
})
export class ModalService {

	private readonly __modal = inject(MatDialog);

	hasOpenedModals$ = merge(
		this.__modal.afterOpened.pipe(map(() => true)),
		this.__modal.afterAllClosed.pipe(map(() => false)),
	);

	getOpenModalById$(id: string): Observable<MatDialogRef<any> | undefined> {
		return this.hasOpenedModals$.pipe(
			map(() => this.__modal.getDialogById(id)),
			map(dialog => dialog?.getState() === MatDialogState.OPEN ? dialog : undefined),
			distinctUntilChanged(),
			shareReplay({ bufferSize: 1, refCount: true }),
		);
	}

	closeAll(): void {
		this.__modal.closeAll();
	}

}
